export const introProjectsData = [
  {
    _id: 1,
    name: "Carré Rouge Center",
    image: "/images/hero_image/carre_rouge.jpg",
    path: "/projects/1",
    region: "Zahleh / Lebanon",
    description: "Façade \n Perforation",
  },
  {
    _id: 2,
    name: "Holcom",
    image: "/images/hero_image/holcom.jpg",
    path: "/projects/1",
    region: "Beirut / Lebanon",
    description: "Façade \n Grid",
  },
  {
    _id: 3,
    name: "Lambourghini & Jaguar Showroom",
    image: "/images/hero_image/lamborghini_jaguar.jpg",
    path: "/projects/1",
    region: "Sin el fil / Lebanon",
    description: "Build \n Identity",
  },
  {
    _id: 4,
    name: "Regent Park",
    image: "/images/hero_image/regent_park.jpg",
    path: "/projects/1",
    region: "Fernel  Chebek / Lebanon",
    description: "Façade \n Cladding",
  },
];
