export const rearVentilationData = [
  {
    title: "Design and structural benefits",
    bullets: [
      "ALUCOBOND® panels have an impressive strength-to-weight ratio",
      "Simple tolerance compensation",
      "Insulation material thickness is easy to vary",
      "All energy-related building standards can be easily attained",
      "Effective protection from rain and condensation",
      "Protects against vandalism (graffiti, mechanical loading)",
    ],
  },
  {
    title: "Energy efficiency",
    bullets: [
      "Protects building interior from heating in the summer, protects against cooling and loss of heat in the winter",
      "A pleasant room climate ensures comfort",
      "Very good vapour diffusion properties (no formation of condensation)",
    ],
  },
  {
    title: "Sustainability/efficiency",
    bullets: [
      "Rear-ventilated facade systems are not very prone to damage",
      "Long facade service life",
      "Low service life costs as very little maintenance and repair work is needed",
      "Easy to disassemble",
      "Can be reused and recycled",
    ],
  },
  {
    title: "Aesthetics",
    bullets: [
      " Combination of materials, surfaces, colours, shapes, joins",
      " Huge variety of colours and surfaces",
      "Influences the architectonic character of a building as the cladding is either",
      "attached in a visible or concealed manner",
      "3D structuring of the facade",
    ],
  },
];
