export const coloursSurfacesData = [
  {
    _id: 1,
    title: "Premium Anodised",
    subTitle: "Premium Anodised Surfaces",
    path: "/colours-surfaces/premium-anodised",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/premium_anodised.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
    textTitle: "Each panel is unique.",
    text: "Anodising is an electrochemical process which converts the surface of a metal into a decorative, durable oxide layer which is both corrosion and wear-resistant. Unlike other coatings, this aluminium oxide is fully integrated with the underlying aluminium substrate, not just applied to the surface.\n\nNot only resilient, the major appeal of anodised aluminium is its beauty. The process of anodising creates a unique oxide layer which can vary from panel to panel due to the batch process. On the one hand, this might mean that you receive a colour which varies slightly from the original colour sample. On the other hand, each façade panel has a truly individual appearance. As the surface of the panels is subject to natural variations, the result is a lively façade with a distinctive character.\n\n The top-quality real anodised series comes in matt or brushed finishes with a colour spectrum ranging from natural anodised to gold, and from copper to darker tones. Time enhances the anodised surfaces: as they gain depth and become more expressive, the authentic beauty of aluminium is accentuated to the full.he top-quality real anodised series comes in matt or brushed finishes with a colour spectrum ranging from natural anodised to gold, and from copper to darker tones. Time enhances the anodised surfaces: as they gain depth and become more expressive, the authentic beauty of aluminium is accentuated to the full.",
    image: "/images/colours-surfaces/visuals/premium_anodised.jpg",
    colours: [
      {
        _id: "E5001",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Bronze C32",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_bronze_C32.jpg",
        path: "/colours-surfaces/premium-anodised/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5002",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Old Copper",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_old_copper.jpg",
        path: "/colours-surfaces/premium-anodised/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5003",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Zinc",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_zinc.jpg",
        path: "/colours-surfaces/premium-anodised/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5004",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Blue Steel",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_blue_steel.jpg",
        path: "/colours-surfaces/premium-anodised/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5141",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Titanium",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_titanium.jpg",
        path: "/colours-surfaces/premium-anodised/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "hight gloss",
          colour_specialities: "brushed",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5142",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Gold",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_gold.jpg",
        path: "/colours-surfaces/premium-anodised/6",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "hight gloss",
          colour_specialities: "brushed",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5143",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Bronze C33",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_bronze_C33.jpg",
        path: "/colours-surfaces/premium-anodised/7",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "hight gloss",
          colour_specialities: "brushed",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5144",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "Black",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_black.jpg",
        path: "/colours-surfaces/premium-anodised/8",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "hight gloss",
          colour_specialities: "brushed",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5281",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "PANELOX® Champagne",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_panelox_champagne.jpg",
        path: "/colours-surfaces/premium-anodised/9",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "special gloss",
          colour_specialities: "structured",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
      {
        _id: "E5282",
        coloursAndSurface: "Premium Anodised Surfaces",
        name: "PANELOX® Basalt",
        image:
          "/images/colours-surfaces/colours/premium_anodised/premium_anodised_panelox_basalt.jpg",
        path: "/colours-surfaces/premium-anodised/10",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS",
          colour_series: "premium anodised",
          colour_scheme: "monochrome",
          colour_gloss: "special gloss",
          colour_specialities: "structured",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_premium_anodised_EN_DE.pdf",
        chart: "Premium Anodised",
      },
    ],
  },
  {
    _id: 2,
    title: "Solid",
    subTitle: "Solid Colours",
    path: "/colours-surfaces/solid",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/solid.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_Solid_and_Metallic_EN_DE.pdf",
    text: "Soft white or bright red? Solid colours are always a good choice when a uniform appearance without special effects is required.",
    image: "/images/colours-surfaces/visuals/solid.jpg",
    colours: [
      {
        _id: "100",
        coloursAndSurface: "Solid Colours",
        name: "Pure White 10",
        image:
          "/images/colours-surfaces/colours/solid/solid_100_pure-white.jpg",
        path: "/colours-surfaces/solid/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "solid, bright",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "101",
        coloursAndSurface: "Solid Colours",
        name: "White 16",
        image: "/images/colours-surfaces/colours/solid/solid_101_white-16.jpg",
        path: "/colours-surfaces/solid/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "solid, bright",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "102",
        coloursAndSurface: "Solid Colours",
        name: "Cream",
        image: "/images/colours-surfaces/colours/solid/solid_102_cream.jpg",
        path: "/colours-surfaces/solid/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "solid, bright",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "103",
        coloursAndSurface: "Solid Colours",
        name: "Beige",
        image: "/images/colours-surfaces/colours/solid/solid_103_beige.jpg",
        path: "/colours-surfaces/solid/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "solid, bright",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "104",
        coloursAndSurface: "Solid Colours",
        name: "Light Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_104_light-grey.jpg",
        path: "/colours-surfaces/solid/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "solid, bright",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "105",
        coloursAndSurface: "Solid Colours",
        name: "Anthracite Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_105_anthracite-grey.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "106",
        coloursAndSurface: "Solid Colours",
        name: "Black Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_106_black-grey.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "107",
        coloursAndSurface: "Solid Colours",
        name: "Umbra Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_107_umbra-grey.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "108",
        coloursAndSurface: "Solid Colours",
        name: "Graphite Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_108_graphite-grey.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "109",
        coloursAndSurface: "Solid Colours",
        name: "Dusty Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_109_dusty-grey.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "110",
        coloursAndSurface: "Solid Colours",
        name: "Quartz Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_110_quartz-grey.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "112",
        coloursAndSurface: "Solid Colours",
        name: "Basalt Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_112_basalt-grey.jpg",
        path: "/colours-surfaces/solid/7",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "136",
        coloursAndSurface: "Solid Colours",
        name: "Platinum Grey",
        image:
          "/images/colours-surfaces/colours/solid/solid_136_platinum-grey.jpg",
        path: "/colours-surfaces/solid/8",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "200",
        coloursAndSurface: "Solid Colours",
        name: "Lemon",
        image: "/images/colours-surfaces/colours/solid/solid_200_lemon.jpg",
        path: "/colours-surfaces/solid/9",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "201",
        coloursAndSurface: "Solid Colours",
        name: "Red",
        image: "/images/colours-surfaces/colours/solid/solid_201_red.jpg",
        path: "/colours-surfaces/solid/10",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "202",
        coloursAndSurface: "Solid Colours",
        name: "Ruby",
        image: "/images/colours-surfaces/colours/solid/solid_202_ruby.jpg",
        path: "/colours-surfaces/solid/11",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, bright, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "210",
        coloursAndSurface: "Solid Colours",
        name: "Orange",
        image: "/images/colours-surfaces/colours/solid/solid_210_orange.jpg",
        path: "/colours-surfaces/solid/12",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "313",
        coloursAndSurface: "Solid Colours",
        name: "Yellow Green",
        image:
          "/images/colours-surfaces/colours/solid/solid_313_yellow-green.jpg",
        path: "/colours-surfaces/solid/13",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "326",
        coloursAndSurface: "Solid Colours",
        name: "Black",
        image: "/images/colours-surfaces/colours/solid/solid_326_black.jpg",
        path: "/colours-surfaces/solid/14",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "350",
        coloursAndSurface: "Solid Colours",
        name: "Steel Blue",
        image:
          "/images/colours-surfaces/colours/solid/solid_350_steel-blue.jpg",
        path: "/colours-surfaces/solid/15",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "solid",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
    ],
  },
  {
    _id: 3,
    title: "Metallic",
    subTitle: "Metallic Colours",
    path: "/colours-surfaces/metallic",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/metallic.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
    text: "Different lighting and viewing angles highlight the typical metallic gloss of these colours.",
    image: "/images/colours-surfaces/visuals/metallic.jpg",
    colours: [
      {
        _id: "500",
        coloursAndSurface: "Metallic Colours",
        name: "Silver Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_500_silver.jpg",
        path: "/colours-surfaces/metallic/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "501",
        coloursAndSurface: "Metallic Colours",
        name: "Smoke Silver Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_501_smoke-solver.jpg",
        path: "/colours-surfaces/metallic/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "502",
        coloursAndSurface: "Metallic Colours",
        name: "Grey Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_502_grey.jpg",
        path: "/colours-surfaces/metallic/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "503",
        coloursAndSurface: "Metallic Colours",
        name: "Champagne Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_503_champagne.jpg",
        path: "/colours-surfaces/metallic/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "504",
        coloursAndSurface: "Metallic Colours",
        name: "Bronze Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_504_bronze.jpg",
        path: "/colours-surfaces/metallic/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "505",
        coloursAndSurface: "Metallic Colours",
        name: "Dark Grey Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_505_dark-grey.jpg",
        path: "/colours-surfaces/metallic/6",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "506",
        coloursAndSurface: "Metallic Colours",
        name: "Steel Grey Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_506_steel-grey.jpg",
        path: "/colours-surfaces/metallic/7",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "dark, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "600",
        coloursAndSurface: "Metallic Colours",
        name: "Sunrise Silver Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_600_sunrise-silver.jpg",
        path: "/colours-surfaces/metallic/8",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "605",
        coloursAndSurface: "Metallic Colours",
        name: "Colorado Gold Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_605_colorado-gold.jpg",
        path: "/colours-surfaces/metallic/9",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "606",
        coloursAndSurface: "Metallic Colours",
        name: "Atacama Bronze Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_606_atacama-bronze.jpg",
        path: "/colours-surfaces/metallic/10",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, dark, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
      {
        _id: "709",
        coloursAndSurface: "Metallic Colours",
        name: "Siena Copper Metallic",
        image:
          "/images/colours-surfaces/colours/metallic/metallic_709_siena-copper.jpg",
        path: "/colours-surfaces/metallic/11",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "metallic",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_solid_and_metallic_EN_DE.pdf",
        chart: "Solid & Metallic",
      },
    ],
  },
  {
    _id: 4,
    title: "Spectra",
    subTitle: "Spectra Colours",
    path: "/colours-surfaces/spectra",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/spectra.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
    text: "Brilliant, iridescent colours found in nature were the inspiration for Spectra colours. Through special colour pigments, various colour gradients of gloss and depth are created. Depending on the type of pigment and viewing angle, enchanting colour effects with highlights and interesting colour gradients add new visual dimensions.",
    image: "/images/colours-surfaces/visuals/spectra.jpg",
    colours: [
      {
        _id: "913",
        coloursAndSurface: "Spectra Colours",
        name: "Cupral",
        image:
          "/images/colours-surfaces/colours/spectra/sepctra_913_cupral.jpg",
        path: "/colours-surfaces/spectra/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "spectra",
          colour_scheme: "polychrome",
          colour_gloss: "high gloss",
          colour_specialities: "colour shift, bright, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "917",
        coloursAndSurface: "Spectra Colours",
        name: "Sakura",
        image:
          "/images/colours-surfaces/colours/spectra/sepctra_917_sakura.jpg",
        path: "/colours-surfaces/spectra/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "spectra",
          colour_scheme: "polychrome",
          colour_gloss: "high gloss",
          colour_specialities: "colour shift, bright, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "918",
        coloursAndSurface: "Spectra Colours",
        name: "Galaxy Blue",
        image:
          "/images/colours-surfaces/colours/spectra/sepctra_918_galaxy-blue.jpg",
        path: "/colours-surfaces/spectra/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "spectra",
          colour_scheme: "polychrome",
          colour_gloss: "high gloss",
          colour_specialities: "colour shift, bright, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "919",
        coloursAndSurface: "Spectra Colours",
        name: "White Gold",
        image:
          "/images/colours-surfaces/colours/spectra/sepctra_919_white-gold.jpg",
        path: "/colours-surfaces/spectra/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "spectra",
          colour_scheme: "polychrome",
          colour_gloss: "high gloss",
          colour_specialities: "colour shift, bright, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "921",
        coloursAndSurface: "Spectra Colours",
        name: "Desert Gold",
        image:
          "/images/colours-surfaces/colours/spectra/sepctra_921_desert-gold.jpg",
        path: "/colours-surfaces/spectra/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "spectra",
          colour_scheme: "polychrome",
          colour_gloss: "high gloss",
          colour_specialities: "colour shift, bright, iridescent, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
    ],
  },
  {
    _id: 5,
    title: "Sparkling",
    subTitle: "Sparkling Colours",
    path: "/colours-surfaces/sparkling",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/sparkling.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
    text: "With their bright sparkling and shining metall effects, ALUCOBOND® sparkling colours provide a subtle and elegant impression.",
    image: "/images/colours-surfaces/visuals/sparkling.jpg",
    colours: [
      {
        _id: "680",
        coloursAndSurface: "Sparkling Colours",
        name: "White Silver MG",
        image:
          "/images/colours-surfaces/colours/sparkling/sparkling_680_white-silver-mg.jpg",
        path: "/colours-surfaces/sparkling/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "sparkling",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "bright, sparkling, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "681",
        coloursAndSurface: "Sparkling Colours",
        name: "Anthracite Silver MG",
        image:
          "/images/colours-surfaces/colours/sparkling/sparkling_681_anthracite-silver-mg.jpg",
        path: "/colours-surfaces/sparkling/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "sparkling",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "dark, sparkling, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "682",
        coloursAndSurface: "Sparkling Colours",
        name: "Cosmic Grey Metallic MG",
        image:
          "/images/colours-surfaces/colours/sparkling/sparkling_682_cosmic-grey-metallic-mg.jpg",
        path: "/colours-surfaces/sparkling/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "sparkling",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "dark, sparkling, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "683",
        coloursAndSurface: "Sparkling Colours",
        name: "Black Metallic MG",
        image:
          "/images/colours-surfaces/colours/sparkling/sparkling_683_black-metallic-mg.jpg",
        path: "/colours-surfaces/sparkling/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "sparkling",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "dark, sparkling, iridescent",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
      {
        _id: "684",
        coloursAndSurface: "Sparkling Colours",
        name: "Deepblue Metallic MG",
        image:
          "/images/colours-surfaces/colours/sparkling/sparkling_684_deepblue-metallic-mg.jpg",
        path: "/colours-surfaces/sparkling/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "sparkling",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "dark, sparkling, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_spectra_sparkling_EN_DE.pdf",
        chart: "Spectra & sparkling",
      },
    ],
  },
  {
    _id: 6,
    title: "NaturAl",
    subTitle: "NaturAl Colours",
    path: "/colours-surfaces/natural",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/natural.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_natural_EN_DE.pdf",
    text: "The natural beauty of metals used in architecture is accentuated particularly by the naturAL surface series. Its imposing, vivid appearance combined with the metallic gloss, light reflection and absorption of these surfaces makes a truly memorable impression.",
    image: "/images/colours-surfaces/visuals/natural.jpg",
    colours: [
      {
        _id: "400",
        coloursAndSurface: "Natural Colours",
        name: "Brushed",
        image:
          "/images/colours-surfaces/colours/natural/natural_400_brushed.jpg",
        path: "/colours-surfaces/natural/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "high gloss",
          colour_specialities: "brushed, stuctured, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "401",
        coloursAndSurface: "Natural Colours",
        name: "Line",
        image: "/images/colours-surfaces/colours/natural/natural_401_line.jpg",
        path: "/colours-surfaces/natural/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "high gloss",
          colour_specialities: "bright, brushed, stuctured, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "405",
        coloursAndSurface: "Natural Colours",
        name: "Reflect",
        image:
          "/images/colours-surfaces/colours/natural/natural_405_reflect.jpg",
        path: "/colours-surfaces/natural/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "high gloss",
          colour_specialities: "bright, brushed, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "402",
        coloursAndSurface: "Natural Colours",
        name: "Pure",
        image: "/images/colours-surfaces/colours/natural/natural_402_pure.jpg",
        path: "/colours-surfaces/natural/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "high gloss",
          colour_specialities: "bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "461",
        coloursAndSurface: "Natural Colours",
        name: "Havanna",
        image:
          "/images/colours-surfaces/colours/natural/natural_461_havanna.jpg",
        path: "/colours-surfaces/natural/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "brushed, structured, metallic",
          minimum_quantities: "Please inquire about current availability",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "470",
        coloursAndSurface: "Natural Colours",
        name: "Titanium",
        image:
          "/images/colours-surfaces/colours/natural/natural_470_titanium.jpg",

        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "brushed, structured, metallic",
          minimum_quantities: "Please inquire about current availability",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "471",
        coloursAndSurface: "Natural Colours",
        name: "Copper",
        image:
          "/images/colours-surfaces/colours/natural/natural_471_copper.jpg",
        path: "/colours-surfaces/natural/6",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "bright, structured, metallic",
          minimum_quantities: "Please inquire about current availability",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
      {
        _id: "462",
        coloursAndSurface: "Natural Colours",
        name: "Manhattan",
        image:
          "/images/colours-surfaces/colours/natural/natural_462_manhattan.jpg",
        path: "/colours-surfaces/natural/7",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "naturAL",
          colour_scheme: "monochrome",
          colour_gloss: "standard",
          colour_specialities: "structured, brushed, dark, metallic",
          minimum_quantities: "Please inquire about current availability",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_naturaL_EN_DE.pdf",
        chart: "NaturAL",
      },
    ],
  },
  {
    _id: 7,
    title: "Anodized Look",
    subTitle: "Anodized Look Surfaces",
    path: "/colours-surfaces/anodized-look",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/anodized_look.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
    text: "In today's architecture, the surfaces of the ALUCOBOND® anodized look series display the natural beauty of satin-smooth metal at its best. The ALUCOBOND® anodized look is used where architects focus on product high quality and appearance – for rainscreen facades, wall cladding, roof perimeter detailing, curved features, column casings, along with many other interior and exterior architectural applications.\n \n Designed to comply with EURAS industrial standards, the surfaces perfectly well match with anodised window frames, profiles and doors – and unlike anodised materials, the ALUCOBOND®anodized look can be smoothly shaped, offering unlimited possibilities for creative ideas.\n \n The use of a top-grade fluorpolymer coating applied in a continuous coil coating process guarantees an uncompromising realisation of the original design.",
    image: "/images/colours-surfaces/visuals/anodized_look.jpg",
    colours: [
      {
        _id: "539",
        coloursAndSurface: "Anodized Look",
        name: "C2 Light Gold",
        image:
          "/images/colours-surfaces/colours/anodized_look/anodized_look_c2_light-gold.jpg",
        path: "/colours-surfaces/anodized-look/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "anodized look",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
        chart: "Anodized Look",
      },
      {
        _id: "538",
        coloursAndSurface: "Anodized Look",
        name: "Satin Brown",
        image:
          "/images/colours-surfaces/colours/anodized_look/anodized_look_538_satin-brown.jpg",
        path: "/colours-surfaces/anodized-look/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "anodized look",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "dark, matt, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
        chart: "Anodized Look",
      },
      {
        _id: "534",
        coloursAndSurface: "Anodized Look",
        name: "C34",
        image:
          "/images/colours-surfaces/colours/anodized_look/anodized_look_c34.jpg",
        path: "/colours-surfaces/anodized-look/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "anodized look",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "dark, matt, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
        chart: "Anodized Look",
      },
      {
        _id: "532",
        coloursAndSurface: "Anodized Look",
        name: "C32",
        image:
          "/images/colours-surfaces/colours/anodized_look/anodized_look_c32.jpg",
        path: "/colours-surfaces/anodized-look/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "anodized look",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt, bright, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
        chart: "Anodized Look",
      },
      {
        _id: "530",
        coloursAndSurface: "Anodized Look",
        name: "C0/EV1",
        image:
          "/images/colours-surfaces/colours/anodized_look/anodized_look_c0-ev1.jpg",
        path: "/colours-surfaces/anodized-look/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "anodized look",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "matt, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
        chart: "Anodized Look",
      },
      {
        _id: "531",
        coloursAndSurface: "Anodized Look",
        name: "C31",
        image:
          "/images/colours-surfaces/colours/anodized_look/anodized_look_c31.jpg",
        path: "/colours-surfaces/anodized-look/6",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2, ALUCORE®",
          colour_series: "anodized look",
          colour_scheme: "monochrome",
          colour_gloss: "matt",
          colour_specialities: "bright, matt, metallic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_anodized_look_EN_DE.pdf",
        chart: "Anodized Look",
      },
    ],
  },
  {
    _id: 8,
    title: "Terra",
    subTitle: "Terra Surfaces",
    path: "/colours-surfaces/terra",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/terra.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
    text: "ALUCOBOND® terra unites the typical crystalline surface and velvety feel found in natural stone with many of the advantages of ALUCOBOND® composite panels. Unlike most natural stone, ALUCOBOND® composite panels are both very thin and light, but offer at the same time a high level of flexural and tensile strength. Large format panels can be easily and accurately fabricated, processed, transported, installed, and, in addition, three-dimensionally shaped by using folding and roll bending techniques. Unlike many stone surfaces, ALUCOBOND® terra is also weatherresistant and colour fast.",
    image: "/images/colours-surfaces/visuals/terra.jpg",
    colours: [
      {
        _id: "380",
        coloursAndSurface: "Terra Surfaces",
        name: "Arctic Frost",
        image: "/images/colours-surfaces/colours/terra/terra_arctic-frost.jpg",
        path: "/colours-surfaces/terra/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "terra",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "haptic, bright, structured, sparkling",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
        chart: "Terra",
      },
      {
        _id: "381",
        coloursAndSurface: "Terra Surfaces",
        name: "Slate",
        image: "/images/colours-surfaces/colours/terra/terra_381_slate.jpg",
        path: "/colours-surfaces/terra/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "terra",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "haptic, sparkling, structured",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
        chart: "Terra",
      },
      {
        _id: "382",
        coloursAndSurface: "Terra surfaces",
        name: "Slate",
        image: "/images/colours-surfaces/colours/terra/terra_382_lava-nera.jpg",
        path: "/colours-surfaces/terra/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "terra",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "dark, haptic, sparkling, structured",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
        chart: "Terra",
      },
      {
        _id: "383",
        coloursAndSurface: "Terra Surfaces",
        name: "Bronze",
        image: "/images/colours-surfaces/colours/terra/terra_383_bronze.jpg",
        path: "/colours-surfaces/terra/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "terra",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities:
            "haptic, colour shift, sparkling, bright, structured",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
        chart: "Terra",
      },
      {
        _id: "384",
        coloursAndSurface: "Terra Surfaces",
        name: "Terracotta",
        image:
          "/images/colours-surfaces/colours/terra/terra_384_terracotta.jpg",
        path: "/colours-surfaces/terra/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "terra",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities: "bright, haptic, sparkling, structured",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
        chart: "Terra",
      },
      {
        _id: "385",
        coloursAndSurface: "Terra Surfaces",
        name: "Pyrite",
        image: "/images/colours-surfaces/colours/terra/terra_385_pyrite.jpg",
        path: "/colours-surfaces/terra/6",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "terra",
          colour_scheme: "polychrome",
          colour_gloss: "special gloss",
          colour_specialities:
            "haptic, structured, sparkling, dark, bright, colour shift",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_terra_EN_DE.pdf",
        chart: "Terra",
      },
    ],
  },
  {
    _id: 9,
    title: "Urban",
    subTitle: "Urban Colours",
    path: "/colours-surfaces/urban",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/urban.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_urban_EN_DE.pdf",
    text: "Urban lifestyle appeals to us. Urban life is characterised by interplays of light and shadows, transparency and colour. The choice of colour is getting more and more important within the urban context – the wish to shape its own living space lies in the nature of mankind. Especially the interaction of glossy and mat surfaces is part of urbanity. Within this context the new colour series ALUCOBOND® urban was developed: muted, extremely mat surfaces provide open space for design – be it detached or in combination with glossy surfaces and glass – the mat colours of ALUCOBOND® urban support the urban character of the building.",
    image: "/images/colours-surfaces/visuals/urban.jpg",
    colours: [
      {
        _id: "360",
        coloursAndSurface: "Urban Colours",
        name: "Traffic White",
        image:
          "/images/colours-surfaces/colours/urban/urban_360_traffic-white.jpg",
        path: "/colours-surfaces/urban/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "urban",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "bright, matt, solid, haptic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_urban_EN_DE.pdf",
        chart: "Urban",
      },
      {
        _id: "361",
        coloursAndSurface: "Urban colours",
        name: "Window Grey",
        image:
          "/images/colours-surfaces/colours/urban/urban_361_window-grey.jpg",
        path: "/colours-surfaces/urban/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "urban",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "bright, matt, solid, haptic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_urban_EN_DE.pdf",
        chart: "Urban",
      },
      {
        _id: "362",
        coloursAndSurface: "Urban Colours",
        name: "Anthracite",
        image:
          "/images/colours-surfaces/colours/urban/urban_362_anthracite.jpg",
        path: "/colours-surfaces/urban/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "urban",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "dark, matt, solid, haptic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_urban_EN_DE.pdf",
        chart: "Urban",
      },
      {
        _id: "363",
        coloursAndSurface: "Urban Colours",
        name: "Jet Black",
        image: "/images/colours-surfaces/colours/urban/urban_363_jet-black.jpg",
        path: "/colours-surfaces/urban/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "urban",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "dark, matt, solid, haptic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_urban_EN_DE.pdf",
        chart: "Urban",
      },
      {
        _id: "364",
        coloursAndSurface: "Urban Colours",
        name: "Iron Oxide Red",
        image:
          "/images/colours-surfaces/colours/urban/urban_364_iron-oxide-red.jpg",
        path: "/colours-surfaces/urban/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "urban",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "bright, matt, solid, haptic",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_urban_EN_DE.pdf",
        chart: "Urban",
      },
    ],
  },
  {
    _id: 10,
    title: "Rocca",
    subTitle: "Rocca Surfaces",
    path: "/colours-surfaces/rocca",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/rocca.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_rocca_EN_DE.pdf",
    text: "Façades take on a rugged stone look with the ALUCOBOND® rocca surface series. Divested of colour, the secret of their special character is only revealed by light refraction on their surface. The rocca range is available in four shades: the soft jet black of graphite, the rugged terrain of Dolomite anthracite, the weathered iron-grey of a meteorite and the crisp silver-white of quartzite.\n ALUCOBOND® rocca features a unique, perceptibly textured structure where light is broken up naturally into the widest range of shades. Depending on your viewing angle and how the light falls, the interface between gloss and matt on this subtle surface structure can be more defined and vibrant or softer and more subtle. This, too, is modelled on natural stone: the nature of the rock dictates a different sheen at the fracture lines.",
    image: "/images/colours-surfaces/visuals/rocca.jpg",
    colours: [
      {
        _id: "809",
        coloursAndSurface: "Rocca Surfaces",
        name: "Graphite",
        image: "/images/colours-surfaces/colours/rocca/rocca_809_graphite.jpg",
        path: "/colours-surfaces/rocca/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "rocca",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "dark, haptic, structured, matt, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_rocca_EN_DE.pdf",
        chart: "Rocca",
      },
      {
        _id: "815",
        coloursAndSurface: "Rocca Surfaces",
        name: "Dolomite",
        image: "/images/colours-surfaces/colours/rocca/rocca_815_dolomite.jpg",
        path: "/colours-surfaces/rocca/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "rocca",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "dark, haptic, structured, matt, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_rocca_EN_DE.pdf",
        chart: "Rocca",
      },
      {
        _id: "805",
        coloursAndSurface: "Rocca Surfaces",
        name: "Meteorite",
        image: "/images/colours-surfaces/colours/rocca/rocca_805_meteorite.jpg",
        path: "/colours-surfaces/rocca/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "rocca",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "bright, haptic, structured, matt, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_rocca_EN_DE.pdf",
        chart: "Rocca",
      },
      {
        _id: "806",
        coloursAndSurface: "Rocca Surfaces",
        name: "Quartzite",
        image: "/images/colours-surfaces/colours/rocca/rocca_806_quartzite.jpg",
        path: "/colours-surfaces/rocca/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "rocca",
          colour_scheme: "monochrome",
          colour_gloss: "supermatt",
          colour_specialities: "bright, haptic, structured, matt, solid",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_rocca_EN_DE.pdf",
        chart: "Rocca",
      },
    ],
  },
  {
    _id: 11,
    title: "Vintage",
    subTitle: "Vintage Surfaces",
    path: "/colours-surfaces/vintage",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/vintage.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
    text: "ALUCOBOND® vintage – expressive and timeless. Natural patina on a material gives it a truly distinctive charm, and individual weathering features create a nostalgic touch. The imperfection and irregularity of the “random” appearance are the very aspects which open up a wide range of new aesthetics when combined with modern architecture.\n\n‘Fine grained and rough concrete’ free of moss growth, ‘industrial and rusty metal’ which does not bleed, ‘copper with a patina’ and ‘antique brass’ without creeping corrosion – with ALUCOBOND® vintage matt surfaces you can hold back time. The weathering and aging processes typical for these materials are halted at their most aesthetic, and the reduced sheen gives the finishes an exclusive and authentic antique look.",
    bullet_pts: true,
    pts: [
      "ALUCOBOND® design offers excellent print quality (direct-to-substrate digital printing) with a particularly wide range of colours and large depth of colour.",
      "Embedded in our high-quality ALUCOBOND® coil coating, ALUCOBOND® vintage offers very durable and UV-stable design surfaces for outdoor use on façades.",
      "The maximum sheet size without decor repetition is 1,500 mm x 5,000 mm;a size of 6,800 mm is possible with vector graphics.",
    ],
    image: "/images/colours-surfaces/visuals/vintage.jpg",
    colours: [
      {
        _id: "870",
        coloursAndSurface: "Vintage Surfaces",
        name: "Patina Copper",
        image:
          "/images/colours-surfaces/colours/vintage/vintage_870_patina-copper.jpg",
        path: "/colours-surfaces/vintage/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "vintage",
          colour_scheme: "polychrome",
          colour_gloss: "matt",
          colour_specialities: "design / decor, matt, bright, colour shift",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
        chart: "Vintage",
      },
      {
        _id: "871",
        coloursAndSurface: "Vintage Surfaces",
        name: "Industrial Iron",
        image:
          "/images/colours-surfaces/colours/vintage/vintage_871_industrial-iron.jpg",
        path: "/colours-surfaces/vintage/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "vintage",
          colour_scheme: "polychrome",
          colour_gloss: "matt",
          colour_specialities: "design / decor, matt, dark, colour shift",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
        chart: "Vintage",
      },
      {
        _id: "880",
        coloursAndSurface: "Vintage Surfaces",
        name: "Coracero",
        image:
          "/images/colours-surfaces/colours/vintage/vintage_880_coracero.jpg",
        path: "/colours-surfaces/vintage/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "vintage",
          colour_scheme: "polychrome",
          colour_gloss: "matt",
          colour_specialities: "design / decor, matt, colour shift",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
        chart: "Vintage",
      },
      {
        _id: "873",
        coloursAndSurface: "Vintage Surfaces",
        name: "Antique Brasso",
        image:
          "/images/colours-surfaces/colours/vintage/vintage_873_antique-brass.jpg",
        path: "/colours-surfaces/vintage/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "vintage",
          colour_scheme: "polychrome",
          colour_gloss: "matt",
          colour_specialities: "design / decor, matt, colour shift",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
        chart: "Vintage",
      },
      {
        _id: "874",
        coloursAndSurface: "Vintage Surfaces",
        name: "Rough Concrete",
        image:
          "/images/colours-surfaces/colours/vintage/vintage_874_rough-concrete.jpg",
        path: "/colours-surfaces/vintage/5",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "vintage",
          colour_scheme: "polychrome",
          colour_gloss: "matt",
          colour_specialities: "design / decor, matt, dark",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
        chart: "Vintage",
      },
      {
        _id: "875",
        coloursAndSurface: "Vintage Surfaces",
        name: "Fresh Concrete",
        image:
          "/images/colours-surfaces/colours/vintage/vintage_875_fresh-concrete.jpg",
        path: "/colours-surfaces/vintage/6",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "vintage",
          colour_scheme: "polychrome",
          colour_gloss: "matt",
          colour_specialities: "design / decor, matt, bright",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_vintage_EN_DE.pdf",
        chart: "Vintage",
      },
    ],
  },
  {
    _id: 12,
    title: "ALUCOBOND® Legno",
    subTitle: "ALUCOBOND® Legno Surfaces",
    path: "/colours-surfaces/alucobond-legno",
    cName: "dropdown-link",
    imgLink: "/images/colours-surfaces/cover_page_charts/legno.jpg",
    pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_legno_EN_DE.pdf",
    text: "ALUCOBOND® legno composite panels are real natural beauties: five different wood look finishes showcase wood at its most exclusive and a special coating adds a grainy structured feel to the surface. It makes the lively wood decors even more authentic and the wood grain pattern is not repeated, even in five-metre long panels.\n\nALUCOBOND® legno composite panels also display their powerful character: The flatness and flexural strength of these lightweight panels, mean ALUCOBOND® legno is ideal for numerous different facades – both in large formats or as narrow slats.\n\nIn addition, the panel's diverse processing options ensure the greatest possible architectural scope. Panels can be folded into complex and multi-dimensional shapes and given a sharp and precise geometry using routing and folding. The extreme durability and weather resistance provided by ALUCOBOND® legno composite panels mean they outperform natural wood. The panels are resistant to fungus and rotting, and protected against moss growth and weathering. ALUCOBOND® legno is fully recyclable and demonstrates a high level of sustainability in the life cycle of the material.",
    bullet_pts: true,
    pts: [
      "ALUCOBOND® legno offers excellent print quality (direct-to-substrate digital printing) with a particularly wide range of colours and large depth of colour.",
      "Embedded in our high-quality ALUCOBOND® coil coating, ALUCOBOND® legno offers very durable and UV-stable design surfaces for outdoor use on façades.",
      "The maximum sheet size without decor repetition is 1,500 mm x 5,000 mm.",
    ],
    image: "/images/colours-surfaces/visuals/legno.jpg",
    colours: [
      {
        _id: "830",
        coloursAndSurface: "ALUCOBOND® Legno Surfaces",
        name: "African Zebrano",
        image:
          "/images/colours-surfaces/colours/legno/legno_830_african-zebrano.jpg",
        path: "/colours-surfaces/alucobond-legno/1",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "ALUCOBOND® legno",
          colour_scheme: "polychrome",
          colour_gloss: "supermatt",
          colour_specialities: "haptic, design | decor, structured",
          more_info: "Maximum format without repetition: 1500 x 5000 mm",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_legno_EN_DE.pdf",
        chart: "ALUCOBOND® Legno",
      },
      {
        _id: "831",
        coloursAndSurface: "ALUCOBOND® Legno Surfaces",
        name: "Aged Oak",
        image: "/images/colours-surfaces/colours/legno/legno_831_aged-oak.jpg",
        path: "/colours-surfaces/alucobond-legnoo/2",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "ALUCOBOND® legno",
          colour_scheme: "polychrome",
          colour_gloss: "supermatt",
          colour_specialities: "haptic, design | decor, structured",
          more_info: "Maximum format without repetition: 1500 x 5000 mm",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_legno_EN_DE.pdf",
        chart: "ALUCOBOND® Legno",
      },
      {
        _id: "832",
        coloursAndSurface: "ALUCOBOND® Legno Surfaces",
        name: "European Walnut",
        image:
          "/images/colours-surfaces/colours/legno/legno_832_european-walnut.jpg",
        path: "/colours-surfaces/alucobond-legno/3",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "ALUCOBOND® legno",
          colour_scheme: "polychrome",
          colour_gloss: "supermatt",
          colour_specialities: "haptic, design | decor, structured",
          more_info: "Maximum format without repetition: 1500 x 5000 mm",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_legno_EN_DE.pdf",
        chart: "ALUCOBOND® Legno",
      },
      {
        _id: "833",
        coloursAndSurface: "ALUCOBOND® Legno Surfaces",
        name: "Antique Pine",
        image:
          "/images/colours-surfaces/colours/legno/legno_833_antique-pine.jpg",
        path: "/colours-surfaces/alucobond-legno/4",
        product_specifications: {
          product_classes: "ALUCOBOND® PLUS, ALUCOBOND® A2",
          colour_series: "ALUCOBOND® legno",
          colour_scheme: "polychrome",
          colour_gloss: "supermatt",
          colour_specialities: "haptic, design | decor, structured",
          more_info: "Maximum format without repetition: 1500 x 5000 mm",
        },
        pdf: "/images/colours-surfaces/colour_chart_pdf/ALUCOBOND_legno_EN_DE.pdf",
        chart: "ALUCOBOND® Legno",
      },
    ],
  },
];
