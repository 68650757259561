import React from "react";

const NotFound = () => {
  return (
    <h1 className="not-found margin-top primary-headline">
      {" "}
      Page Not Found !!
    </h1>
  );
};

export default NotFound;
