export const aboutUsData = [
  {
    title: "Who is ANID?",
    bodytext:
      "ANID, founded in 1981 by Andre Anid, in Lebanon. We are specialized in Design, Supply, Fabrication and Installation of ALUCOBOND® cladding systhems. We are the exclusive distributor of ALUCOBOND® since 1981. The 1st and original ACP, Aluminium Composite Panel, made in Germany. From Inspiration to Assembly: Our goal is to provide the Best Architectural Solutions in the most Efficient and Effective way.",
    image: "/images/aboutus/img-0.jpg",
    alt: "",
    link: "Our Services",
    path: "/services",
  },
  {
    title: "What is ALUCOBOND​‌®?",
    bodytext:
      "ALUCOBOND® is a composite panel consisting of two aluminium cover sheets and a fire-retardant or non-combustible mineral-filled core that stands for sustainable construction quality and the highest creative standards. The façade material is distinguished by its outstanding product attributes such as precise flatness, variety of surfaces and colours as well as excellent formability.",
    image: "/images/aboutus/img-1.jpg",
    alt: "",
    link: "Explore our Projects",
    path: "/projects",
    flipped: true,
  },
  {
    title: "Why use ALUCOBOND​‌®?",
    bodytext:
      "ALUCOBOND® for rear-ventilated façades unites the features of energy-efficient construction, economic viability and architectural quality. The rear-ventilated construction technique is suitable for creating façades on both new and existing buildings as well as roof constructions, Corporate Identity programs and interior applications.",
    image: "/images/aboutus/img-2.jpg",
    alt: "",
    link: "Product Overview",
    path: "/technical#ventilation",
  },
  {
    title: "How to build with ALUCOBOND​‌®",
    bodytext:
      "With its extensive processing possibilities at affordable cost, ALUCOBOND® is ideal for realising unusual projects. It can also be handled directly on the building site using commercially available tools and can be refined in a variety of ways to give your project a unique appearance. Thanks to the excellent durability of the composite material the building can be maintained without any major follow-up investments.",
    image: "/images/aboutus/img-3.jpg",
    alt: "",
    link: "Install it on The Wall",
    path: "/technical#wall",

    flipped: true,
  },
];
