export const menuData = [
  {
    _id: 1,
    title: "Home",
    path: "/",
    cName: "nav-link",
  },
  {
    _id: 2,
    title: "About",
    path: "/about-us",
    cName: "nav-link",
  },
  {
    _id: 3,
    title: "Projects",
    path: "/projects",
    cName: "nav-link",
  },
  {
    _id: 4,
    title: "Colours & Surfaces",
    path: "/colours-surfaces",
    cName: "nav-link nav-link-cs",
    dropdownMenu: true,
  },
  {
    _id: 5,
    title: "Services",
    path: "/services",
    cName: "nav-link",
  },
  {
    _id: 6,
    title: "Contact",
    path: "/contact",
    cName: "nav-link",
  },
];
