export const projectsData = [
  {
    id: "carre-rouge-center",
    name: "Carre Rouge Center",
    path: "/projects/carre-rouge-center",
    building_type: "Shopping Center",
    place: "Zahlé, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Quatrofolio Architects - Viviane Saade & Haytham Samar",
    colour_surface: "NaturAL | Solid & Metallic Colour",
    colour: "Brushed (400) | Red (8201), Black (326), Silver Metallic (500)",
    building_owner: "Maroun Makhoul",
    year: "2017",
    photos: "© Wael Khoury",
    project_description: "",
    image_gallery: "/images/projects/carre_rouge/carre_rouge-2.jpg",
    image: [
      "/images/projects/carre_rouge/carre_rouge-1.jpg",
      "/images/projects/carre_rouge/carre_rouge-2.jpg",
      "/images/projects/carre_rouge/carre_rouge-3.jpg",
      "/images/projects/carre_rouge/carre_rouge-4.jpg",
      "/images/projects/carre_rouge/carre_rouge-5.jpg",
      "/images/projects/carre_rouge/carre_rouge-6.jpg",
      "/images/projects/carre_rouge/carre_rouge-7.jpg",
      "/images/projects/carre_rouge/carre_rouge-8.jpg",
    ],
  },
  {
    id: "holcom",
    name: "Holcom",
    path: "/projects/holcom",
    building_type: "Headquarters",
    place: "Corniche Al Nahr, Beirut",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Lombardini 22 + DEGW",
    colour_surface: "Mirror",
    colour: "Bright Mirror",
    building_owner: "Holcom Group",
    year: "2016",
    photos: "Holcom",
    project_description: "",
    image_gallery: "/images/projects/holcom/holcom-1.jpg",
    image: [
      "/images/projects/holcom/holcom-1.jpg",
      "/images/projects/holcom/holcom-2.jpg",
      "/images/projects/holcom/holcom-3.jpg",
      "/images/projects/holcom/holcom-4.jpg",
      "/images/projects/holcom/holcom-5.jpg",
      "/images/projects/holcom/holcom-6.jpg",
    ],
  },
  {
    id: "sofil_center",
    name: "Sofil Center",
    path: "/projects/sofil_center",
    building_type: "Offices",
    place: "Ashrafieh, Beirut",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Pierre Nehme DPLG",
    colour_surface: "Solid Colour",
    colour: "Ral White 9010",
    building_owner: "Sofil Group",
    year: "1983",
    photos: "Anid",
    project_description: "",
    image_gallery: "/images/projects/sofil_center/sofil_center-1.jpg",
    image: [
      "/images/projects/sofil_center/sofil_center-1.jpg",
      "/images/projects/sofil_center/sofil_center-2.jpg",
      "/images/projects/sofil_center/sofil_center-3.jpg",
      "/images/projects/sofil_center/sofil_center-4.jpg",
      "/images/projects/sofil_center/sofil_center-5.jpg",
      "/images/projects/sofil_center/sofil_center-6.jpg",
      "/images/projects/sofil_center/sofil_center-7.jpg",
    ],
  },
  {
    id: "ellipse_center",
    name: "Ellipse Center",
    path: "/projects/ellipse_center",
    building_type: "Offices / Residentials",
    place: "Ashrafieh, Beirut",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Concept Frank Hammoutene | Developed by Bernard Costa",
    colour_surface: "Solid Colour",
    colour: "Ral White 9010",
    building_owner: "Nadim Comair",
    year: "2000",
    photos: "Anid",
    project_description: "",
    image_gallery: "/images/projects/ellipse_center/ellipse_center-2.jpg",
    image: [
      "/images/projects/ellipse_center/ellipse_center-1.jpg",
      "/images/projects/ellipse_center/ellipse_center-2.jpg",
      "/images/projects/ellipse_center/ellipse_center-3.jpg",
      "/images/projects/ellipse_center/ellipse_center-4.jpg",
      "/images/projects/ellipse_center/ellipse_center-5.jpg",
      "/images/projects/ellipse_center/ellipse_center-6.jpg",
      "/images/projects/ellipse_center/ellipse_center-7.jpg",
    ],
  },
  {
    id: "abc_ashrafieh",
    name: "ABC Achrafieh",
    path: "/projects/abc_ashrafieh",
    building_type: "Shopping Mall",
    place: "Achrafieh, Beirut",
    material: "ALUCOBOND® PLUS",
    architect_planner: "A-Consult Dolly Breidi",
    colour_surface: "Solid Grey Colour",
    colour: "Custom-made",
    building_owner: "ABC Group",
    year: "2003",
    photos: "Anid",
    project_description: "",
    image_gallery: "/images/projects/abc_ashrafieh/abc_ashrafieh-1.jpg",
    image: [
      "/images/projects/abc_ashrafieh/abc_ashrafieh-1.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-2.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-3.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-4.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-5.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-6.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-7.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-8.jpg",
      "/images/projects/abc_ashrafieh/abc_ashrafieh-9.jpg",
    ],
  },

  {
    id: "qubic-square-business-center",
    name: "Qubic Square Business Center",
    path: "/projects/qubic-square-business-center",
    building_type: "Business Offices",
    place: "Sin El Fil, Beirut",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Tony Haddad",
    colour_surface: "NaturAl",
    colour: "Brushed (400)",
    building_owner: "Chalhoub & Chaoul",
    year: "2018",
    photos: "S Anid",
    project_description:
      "Stainless steel Look architecture façade. Canopy and Pillars.",

    image_gallery: "/images/projects/qubic/qubic-1.jpg",
    image: [
      "/images/projects/qubic/qubic-1.jpg",
      "/images/projects/qubic/qubic-2.jpg",
      "/images/projects/qubic/qubic-3.jpg",
      "/images/projects/qubic/qubic-4.jpg",
      "/images/projects/qubic/qubic-5.jpg",
    ],
  },
  {
    id: "yarze_country_club",
    name: "Yarze Country Club",
    path: "/projects/yarze_country_club",
    building_type: "Club",
    place: "Yarze, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Elie Pierre Sabbagh",
    colour_surface: "Sollid & Metallic Colour",
    colour: "Ral White 9010 | Grey Metallic (502)",
    building_owner: "Yarze Country Club",
    year: "2018",
    photos: "S Anid",
    project_description:
      "Circular perforations elevator cab architecture design.",

    image_gallery:
      "/images/projects/yarze_country_club/yarze_country_club-gallery.jpg",
    image: [
      "/images/projects/yarze_country_club/yarze_country_club-1.jpg",
      "/images/projects/yarze_country_club/yarze_country_club-2.jpg",
      "/images/projects/yarze_country_club/yarze_country_club-3.jpg",
      "/images/projects/yarze_country_club/yarze_country_club-4.jpg",
      "/images/projects/yarze_country_club/yarze_country_club-5.jpg",
      "/images/projects/yarze_country_club/yarze_country_club-6.jpg",
      "/images/projects/yarze_country_club/yarze_country_club-7.jpg",
    ],
  },
  {
    id: "zen",
    name: "Zen",
    path: "/projects/zen",
    building_type: "Office Building",
    place: "Beirut, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Andre Bekhazi",
    colour_surface: "Metallic Colour",
    colour: "Grey MetalLic (502)",
    building_owner: "Kamaco sal",
    year: "1998",
    photos: "Anid",
    project_description: "",

    image_gallery: "/images/projects/zen/zen-gallery.jpg",
    image: [
      "/images/projects/zen/zen-1.jpg",
      "/images/projects/zen/zen-2.jpg",
      "/images/projects/zen/zen-3.jpg",
      "/images/projects/zen/zen-4.jpg",
      "/images/projects/zen/zen-5.jpg",
      "/images/projects/zen/zen-6.jpg",
      "/images/projects/zen/zen-7.jpg",
      "/images/projects/zen/zen-8.jpg",
    ],
  },
  {
    id: "jaguar_lamborghini_bentley",
    name: "Jaguar. Lamborghini. Bentley",
    path: "/projects/jaguar_lamborghini_bentley",
    building_type: "Showrooms",
    place: "Corniche El Nahr, Beirut",
    material: "ALUCOBOND® PLUS",
    architect_planner: "UK & Italian Offices",
    colour_surface: "Solid & Metallic Colour",
    colour: "Custom Made",
    building_owner: "Saad & Trad sal",
    year: "2019",
    photos: "S Anid",
    project_description: "",

    image_gallery:
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-1.jpg",
    image: [
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-1.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-2.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-3.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-4.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-5.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-6.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-7.jpg",
      "/images/projects/jaguar_lamborghini_bentley/jaguar_lamborghini_bentley-8.jpg",
    ],
  },
  {
    id: "pepsi",
    name: "SMLC Pepsi Cola",
    path: "/projects/pepsi",
    building_type: "Headquarter",
    place: "Hazmieh, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Pepsi Offices Lebanon",
    colour_surface: "Solid & Metallic Colour",
    colour: "Silver MetalLic - Red & Ultramarine Solid",
    building_owner: "SMLC Pepsi Cola",
    year: "2012",
    photos: "Anid",

    project_description: "",

    image_gallery: "/images/projects/pepsi/pepsi-1.jpg",
    image: [
      "/images/projects/pepsi/pepsi-1.jpg",
      "/images/projects/pepsi/pepsi-2.jpg",
      "/images/projects/pepsi/pepsi-3.jpg",
      "/images/projects/pepsi/pepsi-4.jpg",
      "/images/projects/pepsi/pepsi-5.jpg",
      "/images/projects/pepsi/pepsi-6.jpg",
      "/images/projects/pepsi/pepsi-7.jpg",
    ],
  },
  {
    id: "capital_insurance",
    name: "Capital Insurance.",
    path: "/projects/capital_insurance",
    building_type: "Headquarters",
    place: "Horch Tabet, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Jacques Aboukhaled",
    colour_surface: "Metallic Colour",
    colour: "Grey MetalLic (502)",
    building_owner: "Capital Insurance Group",
    year: "2016",
    photos: "S Anid",
    project_description: "",

    image_gallery:
      "/images/projects/capital_insurance/capital_insurance-gallery.jpg",
    image: [
      "/images/projects/capital_insurance/capital_insurance-1.jpg",
      "/images/projects/capital_insurance/capital_insurance-2.jpg",
      "/images/projects/capital_insurance/capital_insurance-3.jpg",
    ],
  },
  {
    id: "metropolitan_palace",
    name: "Metropolitan Palace",
    path: "/projects/metropolitan_palace",
    building_type: "Hotel",
    place: "Sin El Fil, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Khatib & Alami Office",
    colour_surface: "Metallic Colour",
    colour: "Bronze Metallic",
    building_owner: "Al-Habtoor Group",
    year: "2001",
    photos: "Anid",
    project_description: "",

    image_gallery:
      "/images/projects/metropolitan_palace/metropolitan_palace-gallery.jpg",
    image: [
      "/images/projects/metropolitan_palace/metropolitan_palace-1.jpg",
      "/images/projects/metropolitan_palace/metropolitan_palace-2.jpg",
      "/images/projects/metropolitan_palace/metropolitan_palace-3.jpg",
      "/images/projects/metropolitan_palace/metropolitan_palace-4.jpg",
      "/images/projects/metropolitan_palace/metropolitan_palace-5.jpg",
    ],
  },
  {
    id: "abella_building",
    name: "Abella Building",
    path: "/projects/abella_building",
    building_type: "Headquarter",
    place: "Sin El Fil, Lebanon",
    material: "ALUCOBOND® PLUS",
    architect_planner: "Nabil Azar",
    colour_surface: "Metallic Colour",
    colour: "Smoke Silver MetalLic",
    building_owner: "Abella group",
    year: "1998",
    photos: "Anid",
    project_description: "",

    image_gallery: "/images/projects/abella_bldg/abella_bldg-1.jpg",
    image: [
      "/images/projects/abella_bldg/abella_bldg-1.jpg",
      "/images/projects/abella_bldg/abella_bldg-2.jpg",
      "/images/projects/abella_bldg/abella_bldg-3.jpg",
      "/images/projects/abella_bldg/abella_bldg-4.jpg",
    ],
  },
  // {
  //   id: "allied_bank",
  //   name: "Allied Bank",
  //   path: "/projects/allied_bank",
  //   building_type: "Bank",
  //   place: "Ashrafieh, Lebanon",
  //   material: "ALUCOBOND® PLUS",
  //   architect_planner: "e",
  //   colour_surface: "Metallic Colour",
  //   colour: "Grey MetalLic (502)",
  //   building_owner: "",
  //   year: "",
  //   photos: "Anid",
  //   project_description: "",

  //   image_gallery: "/images/projects/allied_bank/allied_bank-gallery.jpg",
  //   image: [
  //     "/images/projects/allied_bank/allied_bank-1.jpg",
  //     "/images/projects/allied_bank/allied_bank-2.jpg",
  //     "/images/projects/allied_bank/allied_bank-3.jpg",
  //   ],
  // },
];
